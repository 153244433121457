<template>
  <div>
    <div class="head">
      <h4>{{ currentPhase.name }}</h4>
    </div>
    <!-- section body -->
    <div class="wrapper">
      <template v-for="(project, index) in list">
        <v-layout :done="isDone" item :key="project.id">
          <!-- linha 1 -->
          <v-flex>
            <v-layout w-100 align-start wrap head-item>
              <v-flex grow order-xs2 order-sm1>
                <h5>{{ getProjectTypeName(project.projectTypeId) }}</h5>
                <h3 :title="project.shortName">{{ project.shortName }}</h3>
                <h4>R$ {{ formatterHelper.formatMoney(project.totalDonatedValueWithCapturedValue) }}</h4>
              </v-flex>
              <v-flex shrink order-xs1 order-sm2 tools justify-self-end>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <a
                      class="fal fa-globe-americas mr-4"
                      :class="{active: project.published}"
                      v-on="on"
                    ></a>
                  </template>
                  <span v-if="project.published">Projeto publicado</span>
                  <span v-if="!project.published">Projeto não publicado</span>
                </v-tooltip>

                <v-layout align-content-center>
                  <v-switch
                    v-model="project.captureStatus"
                    @change="changeCapturedStatus(project)"
                    class="ma-0"
                    title="Apto para receber recursos"
                  ></v-switch>
                </v-layout>

                <v-menu bottom left>
                  <template v-slot:activator="{ on }">
                    <v-btn icon v-on="on">
                      <v-icon>more_vert</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <template v-for="(item, i) in items">
                      <v-list-tile :key="i" v-if="item.condition(project)">
                        <v-list-tile-title @click="item.event(project)">
                          <i :class="item.icon"></i>
                          {{ item.title }}
                        </v-list-tile-title>
                      </v-list-tile>
                    </template>
                  </v-list>
                </v-menu>
              </v-flex>
            </v-layout>
          </v-flex>
          <!-- linha 2 -->
          <v-flex mt-2>
            <v-layout w-100 align-center row wrap>
              <v-flex menu shrink pr-2>
                <router-link
                  class="fal fa-images"
                  :class="getButtonCollor(project.galleryCheck)"
                  title="Geleria de imagens"
                  :to="{ name: routes.app.ProjectGallery, query: { projectId: project.id }}"
                ></router-link>
                <router-link
                  class="fal fa-file-signature"
                  :class="getButtonCollor(project.documentsCheck)"
                  title="Galeria de documentos"
                  :to="{ name: routes.app.ProjectDocuments, query: { projectId: project.id }}"
                ></router-link>
                <a
                  class="fal fa-calendar-alt"
                  :class="getButtonCollor(project.scheduleCheck)"
                  title="Cronograma de atividades"
                  @click="openActivityList(index)"
                  v-if="project.projectTypeId != ProjectTypes.Ticket"
                ></a>
                <a
                  class="fal fa-comment-alt-lines"
                  :class="getButtonCollor(project.newsCheck)"
                  title="Notícias"
                  @click="openNewsList(index)"
                ></a>
                <router-link
                  class="fal fa-hands-usd"
                  :to="{ name: routes.app.ProjectAccountability, query: { projectId: project.id }}"
                  title="Comprovação de Metas"
                  v-if="project.projectTypeId != ProjectTypes.Ticket && currentPhase.id == projectPhases.Execucao"
                ></router-link>
              </v-flex>
              <v-flex money grow xs-12 wrap>
                <v-layout w-100 align-center wrap>
                  <v-flex percent pr-5 slider grow>
                    <v-slider
                      readonly
                      :value="formatterHelper.formatSlider(project.totalDonatedValueWithCapturedValue, project.totalValue)"
                      thumb-color="#02ACB4"
                      thumb-label="always"
                      color="#02ACB4"
                      class="pr-3"
                    ></v-slider>
                  </v-flex>
                  <v-flex money-values shrink>
                    <div>
                      <h4>{{ project.finalDate == null ? 'Indefinido' : formatterHelper.formatDate(project.finalDate) }}</h4>
                      <h3>R$ {{ formatterHelper.formatMoney(project.totalValue) }}</h3>
                    </div>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
          <CloneModal ref="cloneComponent" :cloneList="cloneList" @clone="cloneProject" />
          <AucAlert
            ref="alertComponent"
            :title="'Confirma a exclusão do projeto ' + project.shortName + ' e todos os seus dados?'"
            confirmButtonText="Sim"
            cancelButtonText="Não"
            @confirm="confirmDeleteProject(project.id)"
          />
        </v-layout>
      </template>
      <ActivityList
        ref="activityListComponent"
        :project="selectedProject"
        :allowEditing="allowActivityEditing()"
        @validationStatusChanged="validateStep"
      ></ActivityList>
      <ProjectNewsList
        ref="newsListComponent"
        :project="selectedProject"
        :allowEditing="allowNewsEditing()"
      />
    </div>
  </div>
</template>

<script type="plain/text">
import Modal from "@/components/Modal.vue";
import ProjectNewsList from "@/views/app/project/modal/ProjectNewsList.vue";
import NewEdit from "@/views/app/project/modal/NewEdit.vue";
import CloneModal from "@/views/app/project/modal/CloneModal.vue";
import { format } from "date-fns";
import { ProjectPhases } from "@/scripts/models/enums/projectPhases.enum";
import ValidateType from "@/scripts/models/enums/validateType.enum";
import {
  ProjectTypes,
  GetProjectTypeName
} from "@/scripts/models/enums/projectTypes.enum";
import { Routes } from "@/scripts/models/enums/routes.enum";
import ActivityList from "@/components/ActivityList.vue";
import AucAlert from "@/components/AucAlert.vue";
import FormatterHelper from "@/scripts/helpers/formatter.helper";
import ProjectClone from "@/scripts/models/projectClone.model";
import Permissions from "@/scripts/models/enums/permissions.enum";
import { mapGetters } from "vuex";
export default {
  components: {
    ActivityList,
    AucAlert,
    Modal,
    ProjectNewsList,
    NewEdit,
    CloneModal
  },
  props: {
    currentPhase: ProjectPhases,
    list: Array,
    service: Object
  },
  computed: {
    ...mapGetters(["hasPermission"])
  },
  data() {
    return {
      selectedProject: null,
      isDone:
        this.currentPhase == ProjectPhases.Encerrado ||
        this.currentPhase == ProjectPhases.Cancelado
          ? true
          : false,
      getProjectTypeName: GetProjectTypeName,
      ProjectTypes: ProjectTypes,
      projectPhases: ProjectPhases,
      validateType: ValidateType,
      readyToPublish: true,
      routes: Routes,
      projectClone: new ProjectClone(),
      formatterHelper: new FormatterHelper(),
      showModalEdit: false,
      showNewsModal: false,
      cloneList: {
        name: "Nome",
        cause: "Causa",
        incentiveLaw: "Lei de Incentivo",
        odsList: "Objetivos de Desenvolvimento Sustentável (ODS)",
        objective: "Objetivo",
        socialProblem: "Problema Social",
        solution: "Solução",
        methodology: "Metodologia",
        targetAudience: "Público alvo do projeto",
        coverage: "Abrangência",
        donationProduct: "Produtos de Doação",
        beneficiaries: "Beneficiários",
        dates: "Datas",
        schedule: "Atividades",
        goals: "Metas",
        investiment: "Investimento",
        bankData: "Conta Bancária",
        conterpart: "Contrapartida",
        supporter: "Principais Apoiadores"
      },
      items: [
        {
          title: "Preview",
          icon: "fal fa-eye",
          condition: function() {
            return true;
          },
          event: function(project) {
            let routeData = this.$router.resolve({
              name: Routes.web.ProjectLP,
              query: { projectId: project.id, edit: true }
            });
            window.open(routeData.href, "_blank");
          }.bind(this)
        },
        {
          title: "Publicar Projeto",
          icon: "fas fa-globe-americas",
          condition: function(project) {
            if (
              project.projectPhaseId == ProjectPhases.Encerrado ||
              project.projectPhaseId == ProjectPhases.Cancelado
            )
              return false;

            return !project.published;
          },
          event: function(project) {
            if (this.checkAllowedToPublish(project)) {
              this.service.publish(project.id).then(
                function() {
                  this.$emit("refresh");
                }.bind(this)
              );
            }
          }.bind(this)
        },
        {
          title: "Suspender Projeto",
          icon: "fas fa-ban text-red",
          condition: function(project) {
            if (
              project.projectPhaseId == ProjectPhases.Encerrado ||
              project.projectPhaseId == ProjectPhases.Cancelado
            )
              return false;

            return project.published;
          },
          event: function(project) {
            this.service.suspend(project.id).then(
              function() {
                this.$emit("refresh");
              }.bind(this)
            );
          }.bind(this)
        },
        {
          title: "Editar",
          icon: "fal fa-pencil",
          condition: function(project) {
            if (this.hasPermission([Permissions.CRUDProjectAll])) {
              return true;
            }

            if (
              project.projectPhaseId == ProjectPhases.Encerrado ||
              project.projectPhaseId == ProjectPhases.Cancelado
            )
              return false;
            else {
              return true;
            }
          }.bind(this),
          event: function(project) {
            if (
              project.projectPhaseId == ProjectPhases.LiberacaoDeRecursos &&
              !this.hasPermission([Permissions.CRUDProjectAll])
            ) {
              this.$store.commit(
                "SET_SNACKBAR_MESSAGE",
                "Não é permitido a edição de projetos durante essa fase."
              );
            } else {
              this.editProject(project);
            }
          }.bind(this)
        },
        {
          title: "Clonar",
          icon: "fal fa-copy",
          condition: function(project) {
            return false;
          },
          event: function(project) {
            var index = this.list.findIndex(x => x.id == project.id);
            this.projectClone.projectId = project.id;
            this.$refs.cloneComponent[index].open(this.projectClone);
          }.bind(this)
        },
        {
          title: "Excluir",
          icon: "fal fa-trash",
          condition: function(project) {
            if (
              project.projectPhaseId == ProjectPhases.Encerrado ||
              project.projectPhaseId == ProjectPhases.Cancelado
            )
              return false;
            else {
              return true;
            }
          },
          event: function(project) {
            var index = this.list.findIndex(x => x.id == project.id);
            this.$refs.alertComponent[index].open();
          }.bind(this)
        }
      ]
    };
  },
  methods: {
    allowActivityEditing() {
      if (this.selectedProject)
        return (
          this.selectedProject.projectPhaseId !=
            ProjectPhases.LiberacaoDeRecursos &&
          this.selectedProject.projectPhaseId != ProjectPhases.Encerrado &&
          this.selectedProject.projectPhaseId != ProjectPhases.Cancelado
        );
      return false;
    },
    allowNewsEditing() {
      if (this.selectedProject)
        return (
          this.selectedProject.projectPhaseId !=
            ProjectPhases.LiberacaoDeRecursos &&
          this.selectedProject.projectPhaseId != ProjectPhases.Encerrado &&
          this.selectedProject.projectPhaseId != ProjectPhases.Cancelado
        );
      return false;
    },
    cloneProject(item) {
      this.projectClone = new ProjectClone();
      this.service.clone(item).then(
        function() {
          this.$emit("refresh");
        }.bind(this)
      );
    },
    openUserEdit() {
      this.showModalEdit = true;
    },
    openNewsList(index) {
      this.selectedProject = this.list[index];
      this.$refs.newsListComponent.open(this.list[index]);
    },
    openActivityList(index) {
      this.selectedProject = this.list[index];
      this.$refs.activityListComponent.open(this.list[index]);
    },
    getButtonCollor(statement) {
      return statement == ValidateType.Default
        ? ""
        : statement == ValidateType.Active
        ? "active"
        : "error";
    },
    changeCapturedStatus(project) {
      this.service.changeCaptureStatus(project.id, project.captureStatus).then(
        function() {
          this.$emit("refresh");
        }.bind(this)
      );
    },
    checkAllowedToPublish(project) {
      // OBRIGATORIEDADES DESCRITAS NA DOCUMENTAÇÃO
      // if (
      // 	!project.stepsValidationStatus.step0 ||
      // 	!project.stepsValidationStatus.step1 ||
      // 	!project.stepsValidationStatus.step2 ||
      // 	!project.stepsValidationStatus.step3 ||
      // 	!project.stepsValidationStatus.step4 ||
      // 	!project.stepsValidationStatus.step5_0 ||
      // 	!project.stepsValidationStatus.step5 ||
      // 	project.galleryCheck != ValidateType.Active ||
      // 	project.documentsCheck != ValidateType.Active ||
      // 	project.scheduleCheck != ValidateType.Active
      // )
      if (project.projectTypeId != ProjectTypes.Ticket) {
        if (
          !project.stepsValidationStatus.step0 ||
          !project.stepsValidationStatus.step1 ||
          !project.stepsValidationStatus.step2 ||
          !project.stepsValidationStatus.step4
        ) {
          this.$store.commit(
            "SET_SNACKBAR_MESSAGE",
            "Os passos precisam estar válidos antes de publicar."
          );
          return false;
        }
      } else {
        if (
          !project.stepsValidationStatus.step0 ||
          !project.stepsValidationStatus.step1 ||
          !project.stepsValidationStatus.step2
        ) {
          this.$store.commit(
            "SET_SNACKBAR_MESSAGE",
            "Os passos precisam estar válidos antes de publicar."
          );
          return false;
        }
      }

      return true;
    },
    confirmDeleteProject(projectId) {
      this.service.deleteProject(projectId).then(
        function() {
          this.$emit("refresh");
        }.bind(this)
      );
    },
    editProject(project) {
      let path = "";
      if (project.projectTypeId == ProjectTypes.Ticket) {
        if (!project.stepsValidationStatus.step1) path = "Step1";
        else if (!project.stepsValidationStatus.step2) path = "Step2";
      } else {
        if (!project.stepsValidationStatus.step1) path = "Step1";
        else if (!project.stepsValidationStatus.step2) path = "Step2";
        else if (!project.stepsValidationStatus.step3) path = "Step3";
        else if (!project.stepsValidationStatus.step4) path = "Step4";
        else if (!project.stepsValidationStatus.step5_0) path = "Step50";
        else if (!project.stepsValidationStatus.step5) path = "Step51";
      }
      if (path === "") path = "Step1";
      this.$router.push({
        name: Routes.app["Project" + path],
        query: { projectId: project.id }
      });
    },
    validateStep() {},
    validate(performMethods) {
      let valid = this.validateComponents(performMethods);
      if (!valid) return false;
      if (
        this.project.scheduleList == null ||
        this.project.scheduleList.length < 3
      ) {
        if (performMethods) {
          this.$store.commit(
            "SET_SNACKBAR_MESSAGE",
            "Cadastre ao menos três atividades"
          );
        }
        return false;
      }
      return true;
    }
  }
};
</script>