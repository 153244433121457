<template>
  <div>
    <v-layout>
      <div class="boxapp">
        <!-- boxapp head -->
        <div class="boxapp-head">
          <!-- <a href="#" @click="$router.go(-1)">
            <faIcon type="fal" name="long-arrow-left" size="24" colorFont="#cfd2dd" />
          </a> -->
          <h1>Projetos</h1>
        </div>
        <div class="filter-list-agroup" v-if="false"> <!-- NÃO ESTÁ PRONTO AINDA. NÃO REMOVER V-IF! -->
          <v-layout row wrap >
            <v-flex sm6 xs12 pr-3 pr-600-0>
              <InputSearch
                  type="text"
                  place-holder="Busca projeto"
                />
            </v-flex>
            <v-flex sm6 xs12 display-flex>
              <InputSelect></InputSelect>
              <v-menu offset-y transition="slide-y-transition" bottom left>
                <template v-slot:activator="{ on }">
                  <button
                    v-on="on"
                    class="menu-list-project elevation-base flex-inherit v-btn--flat v-btn--round"
                  >
                    <Icon name="fal fa-layer-group icon-grouping active" size="24" class="mr-2" colorFont="#c3c6d5" />
                  </button>
                </template>
                <v-list class="options-menu-global">
                  <v-list-tile>
                    <v-list-tile-title class="group-list">
                        <Icon name="fal fa-map-marker-alt" size="16" class="mr-2" colorFont="#C3C6D5" />
                        Localidade
                        <Icon name="fas fa-check-circle" size="16" class="ml-5 mr-0 btn-check-group-list" colorFont="#C3C6D5" />
                    </v-list-tile-title>
                  </v-list-tile>
                  <v-list-tile>
                    <v-list-tile-title class="group-list active">
                      <div>
                        <Icon name="fal fa-hand-receiving" size="16" class="mr-2" colorFont="#C3C6D5" />
                        Causa
                      </div>
                        <Icon name="fas fa-check-circle" size="16" class="ml-5 mr-0 btn-check-group-list" colorFont="#C3C6D5" />
                    </v-list-tile-title>
                  </v-list-tile>
                  <v-list-tile>
                    <v-list-tile-title class="group-list">
                        <Icon name="fal fa-flag" size="16" class="mr-2" colorFont="#C3C6D5" />
                        Status
                        <Icon name="fas fa-check-circle" size="16" class="ml-5 mr-0 btn-check-group-list" colorFont="#C3C6D5" />
                    </v-list-tile-title>
                  </v-list-tile>
                  <v-list-tile>
                    <v-list-tile-title class="group-list">
                        <Icon name="fal fa-box-usd" size="16" class="mr-2" colorFont="#C3C6D5" />
                        Tipo de Projeto
                        <Icon name="fas fa-check-circle" size="16" class="ml-5 mr-0 btn-check-group-list" colorFont="#C3C6D5" />
                    </v-list-tile-title>
                  </v-list-tile>
                  <v-list-tile>
                    <v-list-tile-title class="group-list">
                        <Icon name="fal fa-gavel" size="16" class="mr-2" colorFont="#C3C6D5" />
                        Lei de Incentivo
                        <Icon name="fas fa-check-circle" size="16" class="ml-5 mr-0 btn-check-group-list" colorFont="#C3C6D5" />
                    </v-list-tile-title>
                  </v-list-tile>
                </v-list>
              </v-menu>
            </v-flex>
          </v-layout>
        </div>
        <!-- boxapp body -->
        <div class="box-main">
          <div class="section projects-list">
            <v-layout justify-end w-100 v-if="institutionId">
              <v-btn
                flat
                round
                color="white"
                class="btn-primary flex-inherit"
                large
                @click="newProject()"
              >
                <i class="fas fa-plus mr-2"></i> Novo Projeto
              </v-btn>
            </v-layout>

            <ProjectItem
              v-if="projectList.ProjetosEmCadastramento && projectList.ProjetosEmCadastramento.length > 0"
              :currentPhase="projectPhasesText.ProjetosEmCadastramento"
              :list="projectList.ProjetosEmCadastramento"
              :service="projectService"
              @refresh="refreshList"
            />
            <ProjectItem
              v-if="projectList.AbertoParaReceberDoacoes && projectList.AbertoParaReceberDoacoes.length > 0"
              :currentPhase="projectPhasesText.AbertoParaReceberDoacoes"
              :list="projectList.AbertoParaReceberDoacoes"
              :service="projectService"
              @refresh="refreshList"
            />
            <ProjectItem
              v-if="projectList.ProjetoEmAprovacao && projectList.ProjetoEmAprovacao.length > 0"
              :currentPhase="projectPhasesText.ProjetoEmAprovacao"
              :list="projectList.ProjetoEmAprovacao"
              :service="projectService"
              @refresh="refreshList"
            />
            <ProjectItem
              v-if="projectList.Execucao && projectList.Execucao.length > 0"
              :currentPhase="projectPhasesText.Execucao"
              :list="projectList.Execucao"
              :service="projectService"
              @refresh="refreshList"
            />
            <ProjectItem
              v-if="projectList.LiberacaoDeRecursos && projectList.LiberacaoDeRecursos.length > 0"
              :currentPhase="projectPhasesText.LiberacaoDeRecursos"
              :list="projectList.LiberacaoDeRecursos"
              :service="projectService"
              @refresh="refreshList"
            />
            <ProjectItem
              v-if="projectList.Encerrado && projectList.Encerrado.length > 0"
              :currentPhase="projectPhasesText.Encerrado"
              :list="projectList.Encerrado"
              :service="projectService"
              @refresh="refreshList"
            />
            <ProjectItem
              v-if="projectList.Cancelado && projectList.Cancelado.length > 0"
              :currentPhase="projectPhasesText.Cancelado"
              :list="projectList.Cancelado"
              :service="projectService"
              @refresh="refreshList"
            />
          </div>
        </div>
      </div>

      <div class="box-help-spacer"></div>
      <HelpBox :text="helpBoxText" />
    </v-layout>
  </div>
</template>

<script type="plain/text">
import FooterStep from "@/components/FooterStep.vue";
import ProjectItem from "@/components/ProjectItem.vue";
import ProjectService from "@/scripts/services/project.service";
import { Routes } from "@/scripts/models/enums/routes.enum";
import { ProjectPhasesText } from "@/scripts/models/enums/projectPhases.enum.js";
import HelpBox from "@/components/HelpBox.vue";
import { HelpBoxText } from "@/scripts/models/enums/helpBoxText.enum.js";
import Permissions from "@/scripts/models/enums/permissions.enum";
import store from "@/store.js";

export default {
  components: {
    HelpBox,
    FooterStep,
    ProjectItem
  },
  props: ["pageOptions"],
  data() {
    return {
      items: [
        { title: 'Click Me1' },
        { title: 'Click Me2' },
        { title: 'Click Me3' },
        { title: 'Click Me 2' },
      ],
      entityId: null,
      institutionId: null,
      projectService: new ProjectService(),
      projectList: {},
      projectPhasesText: ProjectPhasesText,
      routes: Routes,
      helpBoxText: HelpBoxText.ProjectList,
      breadcrumbLevels: [
        { text: "Início" },
        { text: "Projetos" }
      ]
    };
  },
  beforeRouteEnter: (to, from, next) => {
    if (
      to.query.entityId &&
      // to.query.institutionId &&
      to.query.entityId != 0
      // to.query.institutionId != 0
    ) {
      if (
        store.getters.hasPermission(
          [Permissions.CRUDProject],
          to.query.entityId
        ) ||
        store.getters.hasPermission([Permissions.CRUDProjectAll])
      ) {
        next();
      } else {
        store.commit(
          "SET_SNACKBAR_MESSAGE",
          "Você não tem permissão para acessar essa página."
        );
        next(false);
      }
    } else {
      next({ name: Routes.app.DashboardPF });
    }
  },
  created() {
    if (this.$route.query.auc) {
      var url =
        location.protocol +
        "//" +
        location.host +
        location.pathname +
        "?entityId=" +
        this.$route.query.entityId;
      location.href = url;
    }
    this.entityId = this.$route.query.entityId;
    this.institutionId = this.$route.query.institutionId;
    this.pageOptions.changeClass(null);
    this.$emit("changeLevels", this.breadcrumbLevels);
    this.refreshList();
  },
  methods: {
    refreshList() {
      this.projectService.listAll(this.entityId, this.institutionId).then(
        function(data) {
          this.projectList = {
            ProjetosEmCadastramento: data.filter(
              this.filterByPhase.bind(
                this,
                this.projectPhasesText.ProjetosEmCadastramento
              )
            ),
            AbertoParaReceberDoacoes: data.filter(
              this.filterByPhase.bind(
                this,
                this.projectPhasesText.AbertoParaReceberDoacoes
              )
            ),
            ProjetoEmAprovacao: data.filter(
              this.filterByPhase.bind(
                this,
                this.projectPhasesText.ProjetoEmAprovacao
              )
            ),
            Execucao: data.filter(
              this.filterByPhase.bind(this, this.projectPhasesText.Execucao)
            ),
            LiberacaoDeRecursos: data.filter(
              this.filterByPhase.bind(
                this,
                this.projectPhasesText.LiberacaoDeRecursos
              )
            ),
            Encerrado: data.filter(
              this.filterByPhase.bind(this, this.projectPhasesText.Encerrado)
            ),
            Cancelado: data.filter(
              this.filterByPhase.bind(this, this.projectPhasesText.Cancelado)
            )
          };
        }.bind(this)
      );
    },
    newProject() {
      this.$router.push({
        name: Routes.app.ProjectStep0,
        query: { institutionId: this.institutionId }
      });
    },
    filterByPhase(currentPhase, item) {
      return item.projectPhaseId === currentPhase.id;
    }
  }
};
</script>
